"use client";

import type { SvgName } from "../../../svg";
import { Button } from "../../ui/button";
import { providers } from "./share.providers";
import { useShare } from "./use-share";

function Share() {
  const { styles, onSharePost } = useShare();

  return (
    <ul className={styles.base()}>
      {providers.map(({ title, url }) => {
        const provider = title.toLowerCase() as SvgName;

        return (
          <li className={styles.item()} key={title}>
            <Button
              appearance="solid"
              aria-label={title}
              color="gray"
              onClick={() => {
                onSharePost(url, provider);
              }}
              rounded
              size="sm"
              startIcon={provider}
              type="button"
            />
          </li>
        );
      })}
    </ul>
  );
}

export default Share;
