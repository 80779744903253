"use client";

import { type ReactNode, createContext, useContext } from "react";
import type { GlobalType } from "../types";

interface AppContextProps {
  global: GlobalType | null;
}

const AppContext = createContext<AppContextProps>({
  global: null,
});

const useAppContext = () => {
  const values = useContext(AppContext);
  return values;
};

type AppProviderProps = {
  children: ReactNode;
  global: GlobalType;
};

function AppProvider({ children, global }: AppProviderProps) {
  return (
    <AppContext.Provider value={{ global }}>{children}</AppContext.Provider>
  );
}

export { AppProvider, useAppContext };
