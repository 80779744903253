"use client";

import {
  createContext,
  useContext,
  useState,
  type PropsWithChildren,
} from "react";
import { useEffectOnce } from "react-use";
import { isBrowser } from "../util/env";

export type QueryParams = Record<string, string | number | boolean>;

interface TrackingContextProps {
  referrer?: string;
  tracking: Record<string, string | number | boolean>;
  queryParams?: QueryParams;
}

interface TrackingProviderProps {
  tracking?: Record<string, string | number | boolean>;
}

const TrackingContext = createContext<TrackingContextProps>({
  referrer: undefined,
  tracking: {},
  queryParams: undefined,
});

const useTrackingContext = () => {
  const values = useContext(TrackingContext);
  return values;
};

function TrackingProvider({
  children,
  tracking = {},
}: PropsWithChildren<TrackingProviderProps>) {
  const [referrer, setReferrer] = useState<string | undefined>();
  const [queryParams, setQueryParams] = useState<QueryParams | undefined>();

  useEffectOnce(() => {
    if (!isBrowser) return;

    if (!referrer && document.referrer) {
      const url = new URL(document.referrer);
      const referrerURL = url.hostname.replace("www.", "");
      !!referrerURL && setReferrer(referrerURL);
    }

    if (!queryParams) {
      const params: QueryParams = {};
      const searchParams = new URLSearchParams(window.location.search);

      searchParams.forEach((value, key) => {
        params[key] = value;
      });

      setQueryParams(params);
    }
  });

  return (
    <TrackingContext.Provider value={{ tracking, referrer, queryParams }}>
      {children}
    </TrackingContext.Provider>
  );
}

export { TrackingProvider, useTrackingContext };
