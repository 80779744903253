import { useMemo } from "react";
import { spinner, type SpinnerVariantProps } from "./spinner.variants";

export type UseSpinnerProps = SpinnerVariantProps & {
  className?: string;
  label?: string;
};

export const useSpinner = (props: UseSpinnerProps) => {
  const {
    className = "",
    label,
    size = "sm",
    color,
    ...componentProps
  } = props;

  const styles = useMemo(() => spinner({ size, color }), [size, color]);

  return {
    className,
    styles,
    label,
    componentProps,
  };
};
