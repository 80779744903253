const splitArray = <T>(arr: T[], numParts: number) => {
  const res: T[][] = [];

  for (let i = 0; i < arr.length; i++) {
    const index = i % numParts;
    if (!res[index]) {
      res[index] = [];
    }

    // @ts-ignore
    res[index].push(arr[i]);
  }

  return res;
};

const splitArrayByHeight = <T>(
  arr: T[],
  itemHeight: number,
  containerHeight: number
) => {
  const maxItemsPerColumn = Math.floor(containerHeight / itemHeight);
  const numColumns = Math.ceil(arr.length / maxItemsPerColumn);

  const res: T[][] = [];

  for (let i = 0; i < arr.length; i++) {
    const columnIndex = i % numColumns;
    if (!res[columnIndex]) {
      res[columnIndex] = [];
    }

    // @ts-ignore
    res[columnIndex].push(arr[i]);
  }

  return res;
};

const isEmptyObject = (obj = {}) => Object.keys(obj).length === 0;

export { splitArray, splitArrayByHeight, isEmptyObject };
