import { tv, type VariantProps } from "../../../util/tv";

export const spinner = tv({
  slots: {
    base: ["animate-spin"],
  },
  variants: {
    size: {
      sm: {
        base: "w-5 h-5",
      },
      md: {
        base: "w-8 h-8",
      },
      lg: {
        base: "w-10 h-10",
      },
    },
    color: {
      dark: {
        base: "text-gray-800",
      },
      light: {
        base: "text-gray-60",
      },
      primary: {
        base: "text-acqua-500",
      },
      secondary: {
        base: "text-acqua-700",
      },
    },
  },
  defaultVariants: {
    size: "sm",
  },
});

export type SpinnerVariantProps = VariantProps<typeof spinner>;
