"use client";

import { useCallback } from "react";
import { useTrackingContext } from "../providers/tracking-provider";
import { UTM_FALLBACK } from "../config/utm";
import { isEmptyObject } from "../util/arr";
import {
  extractURLParams,
  getCTParam,
  getURLWithParams,
  getUTMParams,
} from "../util/utm";

const useUTMParams = () => {
  const { tracking, referrer, queryParams } = useTrackingContext();

  const appendUTMParams = useCallback(
    (url: string, content = "") => {
      const currentUrl = extractURLParams(url);

      const utmParamsFromPage = getUTMParams({
        url,
        params: queryParams,
        content,
        referrer,
      });

      if (utmParamsFromPage && !isEmptyObject(utmParamsFromPage)) {
        return getURLWithParams(currentUrl, utmParamsFromPage);
      }

      const utmFromTracking = getUTMParams({
        url,
        params: tracking,
        content,
        referrer,
      });

      if (utmFromTracking && !isEmptyObject(utmFromTracking)) {
        return getURLWithParams(currentUrl, utmFromTracking);
      }

      return getURLWithParams(currentUrl, {
        utm_source: referrer || UTM_FALLBACK.source,
        click_section: content,
        ct: getCTParam(url),
      });
    },
    [queryParams, referrer, tracking]
  );

  return {
    appendUTMParams,
  };
};

export default useUTMParams;
