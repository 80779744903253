import slugifyLib from "slugify";

const replaceWithMarkup = (str: string, theme = "dark") => {
  const highlightColor = theme === "dark" ? "text-blue-400" : "text-100";

  return str
    .replace(/\n/g, `<br />`)
    .replace(/\\n/g, `<br class="hidden md:block" />`)
    .replace(/\*\*(.*?)\*\*/g, `<span class="${highlightColor}">$1</span>`);
};

const slugify = (content: string) => {
  return slugifyLib(content, {
    lower: true,
    strict: true,
    replacement: "_",
  });
};

export { replaceWithMarkup, slugify };
