"use client";

import NextLink from "next/link";
import { useButton, type UseButtonProps } from "./use-button";
import ButtonContent from "./button-content";

export type ButtonProps = UseButtonProps;

function Button(props: ButtonProps) {
  const {
    className,
    href,
    image,
    subText,
    children,
    styles,
    startIcon,
    endIcon,
    loadingLabel,
    hide,
    newTab,
    title,
    componentProps,
    isLoading,
    isDisabled,
    isWebsiteLink,
    isIconOnly,
  } = useButton(props);

  if (hide) return null;

  if (!href) {
    return (
      <button
        {...componentProps}
        className={styles.base({ className })}
        disabled={isDisabled}
      >
        <ButtonContent
          endIcon={endIcon}
          image={image}
          isIconOnly={isIconOnly}
          isLoading={isLoading}
          loadingLabel={loadingLabel}
          startIcon={startIcon}
          styles={styles}
          subText={subText}
        >
          {children}
        </ButtonContent>
      </button>
    );
  }

  return (
    <NextLink
      {...componentProps}
      className={styles.base({ className })}
      href={href}
      prefetch={!!isWebsiteLink}
      rel={isWebsiteLink ? undefined : "noreferrer"}
      target={newTab ? "_blank" : undefined}
      title={title || undefined}
    >
      <ButtonContent
        endIcon={endIcon}
        image={image}
        isIconOnly={isIconOnly}
        isLoading={isLoading}
        loadingLabel={loadingLabel}
        startIcon={startIcon}
        styles={styles}
        subText={subText}
        title={title}
      >
        {children}
      </ButtonContent>
    </NextLink>
  );
}

export default Button;
