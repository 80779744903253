import { tv, type VariantProps } from "../../../util/tv";

export const shareVariant = tv({
  slots: {
    base: ["flex items-center gap-3"],
    item: [],
  },
});

export type ShareVariantProps = VariantProps<typeof shareVariant>;
