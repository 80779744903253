import { Children } from "react";
import { replaceWithMarkup } from "../../../util/text";
import { useText, type UseTextProps } from "./use-text";

export type TextProps = UseTextProps;

function Text(props: TextProps) {
  const { Component, className, children, theme, styles, componentProps } =
    useText(props);

  return (
    <Component {...componentProps} className={styles.base({ className })}>
      {Children.map(children, (child) => {
        if (
          typeof child === "string" &&
          (child.includes("\\n") ||
            child.includes("\n") ||
            child.includes("**"))
        ) {
          return (
            <span
              dangerouslySetInnerHTML={{
                __html: replaceWithMarkup(child, theme),
              }}
            />
          );
        }

        return child;
      })}
    </Component>
  );
}

export default Text;
