"use client";

import { UTM_FALLBACK, UTM_KEYS } from "../config/utm";
import type { QueryParams } from "../providers/tracking-provider";

const getURLWithParams = (
  url: { path: string; params: QueryParams },
  utm: Record<string, any>
) => {
  const params = Object.entries({ ...url.params, ...utm })
    .filter(([_, value]) => value !== undefined && value !== null)
    .map(([key, value]) => (value === "" ? key : `${key}=${value}`))
    .join("&");

  return `${url.path}${params ? `?${params}` : ""}`;
};

const getUTMParams = ({
  url,
  params = {},
  content,
  referrer,
}: {
  url: string;
  params?: QueryParams;
  content: string;
  referrer?: string;
}) => {
  if (!UTM_KEYS.some((param) => param in params)) return;

  return {
    utm_source: params.utm_source || referrer || UTM_FALLBACK.source,
    utm_medium: getUTMMediumParam(params, referrer),
    utm_campaign: params.utm_campaign,
    utm_content: params.utm_content,
    utm_term: params.utm_term,
    click_section: content,
    ct: getCTParam(url, params),
  };
};

const getCTParam = (url: string, params: QueryParams = {}) => {
  if (url.includes("apps.apple.com")) {
    return params.utm_campaign || UTM_FALLBACK.ct;
  }
};

const getUTMMediumParam = (params: QueryParams, referrer?: string) => {
  if (params.utm_medium) {
    return params.utm_medium;
  }

  if (referrer && referrer.includes("google")) {
    return UTM_FALLBACK.medium_google;
  }
};

const extractURLParams = (url: string) => {
  const hasQueryParams = url.includes("?");
  const urlObject = url.startsWith("http") ? new URL(url) : null;

  let paramsString = "";
  let path = url;

  if (hasQueryParams) {
    if (urlObject) {
      paramsString = urlObject.search;
      path = `${urlObject.origin}${urlObject.pathname}`;
    } else {
      paramsString = url.substring(url.indexOf("?"));
      path = url.substring(0, url.indexOf("?"));
    }
  }

  const params = new URLSearchParams(paramsString);
  const paramsObject = {};

  params.forEach((value, key) => {
    if (!key.startsWith("utm_") && key !== "referrer" && key !== "ct") {
      // @ts-ignore
      paramsObject[key] = value;
    }
  });

  return {
    path,
    params: paramsObject,
  };
};

export {
  getURLWithParams,
  getCTParam,
  getUTMMediumParam,
  getUTMParams,
  extractURLParams,
};
