import { tv as tvBase, type TV, type VariantProps } from "tailwind-variants";
import preset from "../../tailwind.preset";

const tv: TV = (options, config) => {
  return tvBase(options, {
    ...config,
    twMerge: config?.twMerge ?? true,
    twMergeConfig: {
      ...config?.twMergeConfig,
      theme: {
        ...config?.twMergeConfig?.theme,
      },
      classGroups: {
        ...config?.twMergeConfig?.classGroups,
        boxShadow: [
          {
            shadow: Object.keys(preset.theme.boxShadow),
          },
        ],
        "font-size": [
          {
            text: Object.keys(preset.theme.fontSize),
          },
        ],
      },
    },
  });
};

export { tv, type VariantProps };
