"use client";

import {
  type PropsWithChildren,
  createContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from "react";
import Script, { type ScriptProps } from "next/script";

interface HubspotContextProps {
  loaded: boolean;
  error: boolean;
}

const HubspotContext = createContext<HubspotContextProps>({
  loaded: false,
  error: false,
});

const useHubspotContext = () => {
  const values = useContext(HubspotContext);
  return values;
};

function HubspotProvider({
  children,
  id = "hubspotEmbedScript",
  src = "https://js.hsforms.net/forms/v2.js",
  strategy = "afterInteractive",
}: PropsWithChildren<Partial<ScriptProps>>) {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setLoaded(false);
    setError(false);
  }, [src]);

  const onLoad = useCallback(() => {
    setLoaded(true);
  }, []);

  const onError = useCallback(() => {
    setError(true);
  }, []);

  const value = useMemo(() => ({ loaded, error }), [loaded, error]);

  return (
    <HubspotContext.Provider value={value}>
      {children}
      <Script
        id={id}
        onError={onError}
        onLoad={onLoad}
        src={src}
        strategy={strategy}
      />
    </HubspotContext.Provider>
  );
}

export { HubspotProvider, useHubspotContext };
