import { usePathname } from "next-intl/client";
import { dispatchGoogleEvent } from "../../../util/google";
import { EventAction } from "../../../config/events";
import { baseUrl } from "../../../util/env";
import { slugify } from "../../../util/text";
import { shareVariant } from "./share.variants";

export const useShare = () => {
  const styles = shareVariant();
  const pathname = usePathname();

  const onSharePost = (url: string, provider: string) => {
    const shareRefer = `${baseUrl}${pathname}`;
    const slug = slugify(document.title);

    dispatchGoogleEvent(`${EventAction.WebsiteShare}_${provider}`, {
      label: `post_${slug}`,
    });

    const shareUrl = url.replace("{shareRefer}", shareRefer);

    window.open(shareUrl);
  };

  return {
    styles,
    onSharePost,
  };
};
