"use client";

import type { SVGProps } from "react";
import { svg, type SvgName } from "../../../svg";

export type VectorProps = SVGProps<SVGElement> & {
  className?: string;
  name: SvgName;
};

function Vector({ className = "", name, ...rest }: VectorProps) {
  const Component = svg[name];

  if (!Component) {
    return null;
  }

  return (
    <Component
      {...rest}
      aria-hidden="true"
      className={`${className} shrink-0 select-none outline-none`}
      focusable="false"
      tabIndex="-1"
    />
  );
}

export default Vector;
