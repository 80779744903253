import NextImage from "next/image";
import { Spinner } from "../../util/spinner";
import type { UseButtonProps } from "./use-button";
import type { ButtonReturnVariantProps } from "./button.variants";
import ButtonIcon from "./button-icon";

type ButtonContentProps = Pick<
  UseButtonProps,
  | "children"
  | "subText"
  | "image"
  | "startIcon"
  | "endIcon"
  | "isLoading"
  | "loadingLabel"
  | "title"
> & {
  styles: ButtonReturnVariantProps;
  isIconOnly: boolean;
};

function ButtonContent({
  styles,
  title,
  children,
  subText,
  startIcon,
  endIcon,
  image,
  isIconOnly,
  isLoading,
  loadingLabel,
}: ButtonContentProps) {
  if (image) {
    return (
      <NextImage
        alt={image.alternativeText}
        className={styles.image()}
        height={image.height}
        src={image.url}
        width={image.width}
      />
    );
  }

  return (
    <>
      {isLoading ? (
        <Spinner className={styles.icon()} label={loadingLabel} />
      ) : null}
      {isIconOnly && !!title ? <span className="sr-only">{title}</span> : null}
      <>
        {!isLoading && (
          <ButtonIcon className={styles.icon()} icon={startIcon} />
        )}
        {!subText ? (
          children
        ) : (
          <span className={styles.subTextWrapper()}>
            <span className={styles.subText()}>{subText}</span>
            {children}
          </span>
        )}
        {!isLoading && <ButtonIcon className={styles.icon()} icon={endIcon} />}
      </>
    </>
  );
}

export default ButtonContent;
