const link = {
  white: `text-white hover:text-gray-40`,
  black: `text-black hover:text-gray-600`,
  gray: `text-gray-60 hover:text-gray-1`,
  grayDark: `text-gray-800 hover:text-gray-300`,
  twoGrays: `text-gray-20 hover:text-gray-50`,
  blue: `text-blue-200 hover:text-blue-400`,
  blueDark: `text-blue-600 hover:text-blue-700`,
};

const solid = {
  white: `border-white bg-white text-black hover:border-gray-20 hover:bg-gray-20`,
  black: `border-black bg-black text-white hover:border-gray-600 hover:bg-gray-600`,
  gray: `border-gray-400 bg-gray-400 text-white hover:border-gray-200 hover:bg-gray-200`,
  grayDark: `border-gray-800 bg-gray-800 text-white hover:border-gray-300 hover:bg-gray-300`,
  twoGrays: `border-gray-90 bg-gray-500 text-gray-20 hover:border-gray-100 hover:bg-gray-600`,
  blue: `border-blue-200 bg-blue-200 text-white hover:border-blue-300 hover:bg-blue-300`,
  blueDark: `border-blue-600 bg-blue-600 text-white hover:border-blue-700 hover:bg-blue-700`,
};

const outline = {
  white: `border-white text-white hover:bg-white hover:text-black`,
  black: `border-black text-black hover:bg-black hover:text-white`,
  gray: `border-gray-1 text-gray-1 hover:bg-gray-90 hover:border-gray-90 hover:text-white`,
  grayDark: `border-gray-800 text-gray-800 hover:bg-gray-800 hover:text-white`,
  twoGrays: `border-gray-90 text-gray-20 hover:border-gray-90 hover:text-gray-50`,
  blue: `border-blue-200 text-blue-200 hover:bg-blue-200 hover:text-white`,
  blueDark: `border-blue-600 text-blue-600 hover:bg-blue-600 hover:text-white`,
};

const ghost = {
  white: `text-white hover:bg-gray-400 hover:border-gray-400`,
  black: `text-black hover:border-black hover:bg-black hover:text-white`,
  gray: `text-gray-90 hover:border-gray-1 hover:bg-gray-1 hover:text-gray-90`,
  grayDark: `text-gray-800 hover:text-white`,
  twoGrays: `text-gray-20 hover:text-gray-50`,
  blue: `text-blue-200 hover:border-blue-400 hover:bg-blue-400 hover:text-white`,
  blueDark: `text-blue-600 hover:border-blue-700 hover:bg-blue-700 hover:text-white`,
};

export { link, solid, outline, ghost };
