import { gaTrackId, isBrowser, isPreview } from "./env";

export interface GoogleEventProps {
  eventName: string;
  eventParams: Record<string, unknown>;
}

export const dispatchGoogleEvent = (
  eventName: GoogleEventProps["eventName"],
  eventParams: GoogleEventProps["eventParams"] | string
) => {
  if (isPreview || !isBrowser || !gaTrackId) return;

  const params =
    typeof eventParams === "string" ? { label: eventParams } : eventParams;

  if (typeof gtag !== "undefined") {
    gtag("event", eventName, params);
  }

  if (typeof fbq !== "undefined") {
    fbq("trackCustom", eventName, params);
  }
};

export const pageView = (url: string) => {
  if (typeof gtag !== "undefined") {
    gtag("config", gaTrackId, {
      page_path: url,
    });
  }
};
