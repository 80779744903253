import type { SvgItem } from "../../../types";
import { Vector } from "../vector";

export interface ButtonIconProps {
  className?: string;
  icon?: SvgItem;
}

function ButtonIcon({ className = "", icon }: ButtonIconProps) {
  if (!icon) {
    return null;
  }

  const iconName = typeof icon === "string" ? icon : icon.name;

  return <Vector className={className} name={iconName} />;
}

export default ButtonIcon;
